.TermsConditionsCon1 {
  box-sizing: border-box;
  z-index: 1000;
  background: #000000a3;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0 12px;
  display: flex;
  position: absolute;
  top: 0;
}

.TermsConditionsCon2 {
  background: var(--app-white, #fff);
  border-radius: 12px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  display: flex;
  box-shadow: 0 4px 12px 2px #00000080;
}

.TermsConditionsText1 {
  color: var(--app-green, #3a7d44);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.TermsConditionsCon3 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  height: 50vh;
  padding: 12px 0;
  display: flex;
  overflow: hidden scroll;
}

.TermsConditionsCon4 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.TermsConditionsText2 {
  color: var(--app-green, #3a7d44);
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.TermsConditionsText3 {
  color: var(--app-green, #3a7d44);
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.TermsConditionsCon5 {
  border-top: 1px solid var(--app-charcoal-dark, #2b2b2b);
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  display: flex;
}

.TermsConditionsText4 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  flex: 1 0 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/*# sourceMappingURL=index.776e6373.css.map */
