.TermsConditionsCon1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0 12px;
  box-sizing: border-box;

  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.638);
  top: 0;
  z-index: 1000;
}
.TermsConditionsCon2 {
  display: flex;
  padding: 12px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.5);
}
.TermsConditionsText1 {
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.TermsConditionsCon3 {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}
.TermsConditionsCon4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.TermsConditionsText2 {
  align-self: stretch;
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.TermsConditionsText3 {
  align-self: stretch;
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.TermsConditionsCon5 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--app-charcoal-dark, #2b2b2b);
}
.TermsConditionsText4 {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
